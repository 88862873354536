import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

import defaultCardImage from "images/shield-icon.svg";
import ImageProcessingIconImage from "images/ServicesIcons/HPC.svg";

import VideoIconImage from "images/ServicesIcons/data.svg";
import ComputerVisionIconImage from "images/ServicesIcons/optimization.svg";
import ObjectRecognitionIconImage from "images/ServicesIcons/consulting.svg";
import DeepLearningIconImage from "images/ServicesIcons/custom.svg";
import RustIconImage from "images/ServicesIcons/maintenance.svg";

const Container = tw.div`relative bg-primary-900 -mx-8 px-8 text-gray-100`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const Subheading = tw(SubheadingBase)`mb-4 text-gray-100`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center text-gray-300`;

const VerticalSpacer = tw.div`mt-10 w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-xs`}
`;

const Card = styled.div`
  ${tw`flex flex-col items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`bg-gray-100 text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw`mt-6`}
  }

  .title {
    ${tw`tracking-wider font-bold text-xl leading-none`}
  }

  .description {
    ${tw`mt-2 font-normal text-gray-400 leading-snug`}
  }
`;

export default ({
  cards = null,
  heading = "Our services",
  subheading = "",
  description = "We offer the full spectrum of services to build HPC solutions."
}) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const defaultCards = [
    {
      imageSrc: ImageProcessingIconImage,
      title: "Advanced HPC Algorithms Development",
      description: " We create HPC algorithms that boost processing speed and efficiency for industries from scientific research to financial modeling"
    },
    {
      imageSrc: VideoIconImage, title: "Innovative Data Structures"
      , description: "Our team designs novel data structures to optimize memory and accelerate data processing in high-performance environments."
    },
    { imageSrc: ComputerVisionIconImage, title: "Performance Optimization", description: "We provide performance optimization to fine-tune algorithms and systems for peak efficiency and reduced resource use." },
    { imageSrc: DeepLearningIconImage, title: "Custom HPC Solutions", description: "We deliver custom HPC solutions with tailored algorithms and data structures to meet your specific needs" },
    { imageSrc: ObjectRecognitionIconImage, title: "Consulting Services", description: "Our consulting services provide expert guidance on HPC best practices, technology integration, and system design." },
    {
      imageSrc: RustIconImage, title: "Ongoing Support and Maintenance", description: "We provide ongoing support and maintenance to ensure the long-term success and optimal performance of your HPC systems."
    }
  ];

  if (!cards) cards = defaultCards;

  return (
    <Container>
      <div id="ourservices"></div>
      <ThreeColumnContainer>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        {description && <Description>{description}</Description>}
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
    </Container>
  );
};
